import { Col, Input, Modal, Select, Space, Table, Tooltip } from "antd";
import React, { useState, useEffect } from "react";

import { subjects_by_category } from "../../Redux/AllApi/action";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { allapiAction } from "../../Redux/common/action";
import SvgIcon from "../const/SvgIcon";
import {
  ExclamationCircleFilled,
  FileOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { AsyncPaginate } from "react-select-async-paginate";
import { URL } from "../../Redux/common/url";

function BatchStudymaterial() {
  const { id } = useParams();

  const [batchName, setBatchName] = useState("");
  const [selectedSubject, setSelectedSubject] = useState();
  const [selectedSubject1, setSelectedSubject1] = useState();
  const [selectedmaterial, setSelectedmaterial] = useState();
  const [selectedSubjectName, setSelectedSubjectName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDelelt, setShowDelete] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [seleceteManger, setseleceteManger] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setsearch] = useState("");

  const handleCloseDelete = () => setShowDelete(false);
  const dispatch = useDispatch();
  const batch_detail = useSelector((state) =>
    state?.allapi?.batch_detail ? state?.allapi?.batch_detail : {}
  );
  const handleChangesubject = (selectedValues) => {
    setSelectedSubject(selectedValues);
    // setSelectedSubject("")
    const selectedSubjectObj = subjectlist.find(
      (subject) => subject?.subject?.id === selectedValues
    );
    setSelectedSubjectName(selectedSubjectObj?.subject?.name || "");

    // Dispatch the API call when a subject is selected
    dispatch(
      allapiAction.study_materiallist({
        course_id: batch_detail?.category_id,
        batch_id: batch_detail?.id,
        subject_id: selectedValues,
        page: 1,
        // course_id: batch_detail?.category_id,
      })
    );
  };

  useEffect(() => {
    if (batch_detail) {
      dispatch(
        allapiAction.study_materiallist({
          course_id: batch_detail?.category_id,
          batch_id: batch_detail?.id,
          // subject_id: selectedValues,
          page: 1,
          // course_id: batch_detail?.category_id,
        })
      );
    }
  }, [batch_detail]);

  console.log(seleceteManger);
  const handleChangesubject1 = (selectedValues) => {
    setSelectedSubject1(selectedValues);
  };

  console.log(id);
  const subjects_by_category = useSelector((state) =>
    state?.allapi?.subjects_by_category
      ? state?.allapi?.subjects_by_category
      : {}
  );

  const study_materiallist = useSelector((state) =>
    state?.allapi?.study_materiallist ? state?.allapi?.study_materiallist : {}
  );
  console.log(study_materiallist);

  const material_by_subject = useSelector((state) =>
    state?.allapi?.material_by_subject ? state?.allapi?.material_by_subject : {}
  );
  console.log(material_by_subject);

  const add_studymaterialBatch = useSelector((state) =>
    state?.allapi?.add_studymaterialBatch
      ? state?.allapi?.add_studymaterialBatch
      : {}
  );
  console.log(add_studymaterialBatch);

  useEffect(() => {
    dispatch(allapiAction.batch_detail({ batch_id: id }));
  }, [id]);

  // useEffect(() => {
  //   if (batch_detail?.data) {
  //     setBatchName(batch_detail.data.name);
  //   }
  // }, [batch_detail]);
  // dispatch(allapiAction.batch_detail())

  useEffect(() => {
    if (batch_detail) {
      dispatch(
        allapiAction.subjects_by_category({
          category_id: batch_detail?.category_id,
        })
      );
    }
  }, [id, batch_detail]);

  // useEffect(() => {
  //   dispatch(
  //     allapiAction.material_by_subject({
  //       course_id: batch_detail?.category_id,
  //       subject_id: selectedSubject,
  //       page: 1,
  //       search: search, // Pass the 'search' state variable here
  //     })
  //   );
  // }, [dispatch, batch_detail?.category_id, selectedSubject, search]);

  // useEffect(() => {
  //   dispatch(
  //     allapiAction.add_studymaterialBatch({
  //       category_id: batch_detail?.category_id,
  //       subject_id: selectedSubject,
  //     })
  //   );
  // }, [dispatch, id]);

  const subjectlist = subjects_by_category?.subjects;
  const options2 = (subjectlist || []).map((subjectlist) => ({
    label: subjectlist?.subject?.name,
    value: subjectlist?.subject?.id,
  }));

  const materiallist = material_by_subject?.material;
  const options3 = (materiallist || []).map((materiallist) => ({
    label: materiallist?.material?.name,
    value: materiallist?.material?.id,
  }));

  const getImageUrl = (path) => {
    return `${URL.API_BASE_URL}${path}`;
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = (item) => {
    // setcateDetail(item);
    // setSelectedSubject(item.subject_id);
    // setSelectedSubjects(item.course_id);
    setIsModalOpen(true);
  };
  const showModalAdd = () => {
    setAddModalOpen(true);
    //  dispatch(allapiAction.material_by_subject({course_id: batch_detail?.category_id,subject_id:selectedSubject, page:1,search:search}));
  };
  // const showModalAdd = (e) => {
  //   console.log(e);
  //   // setcateDetail(e);
  //   setAddModalOpen(true);
  // };

  const handleOkAdd = async () => {
    let data = new FormData();
    data.append("batch_id", id);

    seleceteManger &&
      seleceteManger?.map((datam, i) => {
        data.append("study_id[" + i + "]", datam?.id);
      });

    const response = await dispatch(allapiAction.add_studymaterialBatch(data));

    setAddModalOpen(false);

    if (response?.status) {
      dispatch(
        allapiAction.study_materiallist({
          course_id: batch_detail?.category_id,
          batch_id: batch_detail?.id,
          // subject_id: selectedValues,
          page: 1,
          // course_id: batch_detail?.category_id,
        })
      );
    }
  };

  const hendleDelete = (e) => {
    // console.log(e);
    // deleteProduct
    setShowDelete(false);
    dispatch(allapiAction.delete_study_material({ id: e, page: page }));
    dispatch(allapiAction.study_materiallist(page));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    if (e) {
      dispatch(
        allapiAction.material_by_subject({
          page: 1,
          search: e,
          subject_id: selectedSubject, // Add this line to pass the selected subject
          course_id: batch_detail?.category_id,
        })
      );
    } else {
      dispatch(
        allapiAction.material_by_subject({
          page: 1,
          subject_id: selectedSubject, // Add this line to pass the selected subject
          course_id: batch_detail?.category_id,
        })
      );
    }
  };

  const loadOptionsgetManager = async (search, loadedOptions, page) => {
    const token = await localStorage.getItem("admin_token");

    // roledataUpdate

    console.log(search);
    console.log(page);

    console.log(selectedSubject1);
    // http://192.168.0.2:8000/api/getStudyMaterialSubjectPaginate?subject_id=1&course_id=5&page=1&search=ba

    const response = await fetch(
      URL.API_BASE_URL +
        `/api/getStudyMaterialSubjectPaginate?search=${search}&page=${page?.page}&subject_id=${selectedSubject1}&course_id=${batch_detail?.category_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseJSON = await response.json();

    console.log(responseJSON);

    const options = responseJSON?.data?.map((data) => {
      data.value = data?.id;
      data.label = data?.name;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page?.page + 1,
      },
    };
  };

  const hendlePahegi = (e) => {
    setPage(e);
    if (search) {
      dispatch(allapiAction.material_by_subject({ page: e, search: search }));
      return () => {};
    } else {
      dispatch(allapiAction.material_by_subject(e));

      return () => {};
    }
  };

  const { confirm } = Modal;
  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Delete Study Material",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // const dataSource = [
  //   {
  //     key: "1",
  //     name: "Mike",
  //     age: 32,
  //     address: "10 Downing Street",
  //   },
  //   {
  //     key: "2",
  //     name: "John",
  //     age: 42,
  //     address: "10 Downing Street",
  //   },
  // ];
  const dataSource = study_materiallist?.data || [];

  const getFileExtension = (url) => {
    const parts = url.split(".");
    return parts[parts.length - 1];
  };

  // Utility function to get icon based on file extension
  // Utility function to get icon based on file extension
  // Utility function to get icon based on file extension
  const getIconForExtension = (extension, imageUrl) => {
    switch (extension.toLowerCase()) {
      case "pdf":
        return (
          <FilePdfOutlined
            style={{
              color: "red",
              marginRight: "5px",
              marginLeft: "3px",
              marginTop: "-4px",
            }}
          />
        );
      case "doc":
      case "docx":
        return (
          <FileOutlined
            style={{
              color: "blue",
              marginRight: "5px",
              marginLeft: "3px",
              marginTop: "-4px",
            }}
          />
        );
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return (
          <img
            src={imageUrl}
            alt="Image File"
            style={{ width: "20px", marginRight: "5px", marginLeft: "3px" }}
          />
        );
      // Add more cases for other file types as needed
      default:
        return <FileOutlined />;
    }
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    // {
    //   title: "Material Name",
    //   dataIndex: "name",
    //   key: "name",
    // },

    {
      title: "Material Name",
      dataIndex: "name",
      key: "name",
      render: (name, item) => <>{item?.study_material?.name}</>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (name, item) => <>{item?.study_material?.description}</>,
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    // },
    {
      title: "Documents",
      dataIndex: "documents",
      key: "documents",
      render: (documents, item) => (
        <div>
          {item?.study_material?.documents &&
          item?.study_material?.documents.length > 0 ? (
            item?.study_material?.documents.map((document, index) => (
              <a
                key={index}
                href={getImageUrl(document.document)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {index + 1}
                {getIconForExtension(
                  getFileExtension(document.document),
                  getImageUrl(document.document)
                )}
                <span>{getFileExtension(document.document)}</span> ,
              </a>
            ))
          ) : (
            <span>No documents </span>
          )}
        </div>
      ),
    },

    // {
    //   title: "Attachment Type",
    //   dataIndex:  ["type", "name"],
    //   key: "name",
    // },
    // {
    //   title: "Actions",
    //   dataIndex: "action",
    //   key: "action",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //           {/* <Tooltip title="Stu">
    //           <span
    //             className="cursor-pointer"
    //             onClick={() => {
    //               showModalstudent(item?.id);
    //             }}
    //           >
    //             <UsergroupAddOutlined className="scale-icon edit-icon" />
    //           </span>
    //         </Tooltip> */}
    //         <Tooltip title="Edit">
    //           <span
    //             className="cursor-pointer"
    //             onClick={() => {
    //               showModal(item);
    //             }}
    //           >
    //             <SvgIcon.Edit className="scale-icon edit-icon" />
    //           </span>
    //         </Tooltip>
    //         {/* <Tooltip title="Delete">
    //           <span
    //             className="cursor-pointer"
    //             onClick={() => {
    //                             hendleModalShow(item?.id)
    //                           }}
    //           >
    //             <SvgIcon.deletebtn className="scale-icon edit-icon" />
    //           </span>
    //         </Tooltip> */}
    //         {/* <Tooltip title="Delete">
    //           <span
    //             className="cursor-pointer"
    //             onClick={() => {
    //               showDeleteConfirm(item?.id);
    //             }}
    //           >
    //             <SvgIcon.deletebtn className="scale-icon edit-icon" />
    //           </span>
    //         </Tooltip> */}
    //       </>
    //     );
    //   },
    // },
  ];
  return (
    <div>
      <div className="batchstudymaterial">
        <Col lg={24} md={24} sm={24} xs={24}>
          <p>Batch Name: {batch_detail?.name}</p>
          <p>subject category: {batch_detail?.category_id}</p>
          <div className="flex1 flex justify-content-end align-items-center">
            <Tooltip title="Add">
              <span className="jop_btn" onClick={() => showModalAdd()}>
                Assign
                <SvgIcon.Add
                  width={40}
                  height={40}
                  className="cursor-pointer"
                />
              </span>
            </Tooltip>
          </div>
          <Select
            id="subject"
            mode="single"
            style={{ width: "100%" }}
            placeholder="Select Subject "
            defaultValue={[]}
            value={selectedSubject}
            onChange={handleChangesubject}
            optionLabelProp="label"
            options={options2}
            optionRender={(option) => <Space>{option.label}</Space>}
          />
        </Col>
      </div>

      <Table
        dataSource={dataSource} // Replace 'dataSource' with your actual data source
        columns={columns} // Replace 'columns' with your actual column configuration
        pagination={false} // You can enable pagination if needed
      />
      <Modal
        title="Add Modal"
        visible={addModalOpen}
        onCancel={() => setAddModalOpen(false)}
        // footer={null}
        onOk={handleOkAdd}
      >
        {/* Add your modal content here */}
        <p>Batch Name: {batch_detail?.name}</p>
        <p>Batch id: {batch_detail?.id}</p>
        {/* <p>Selected Subject: {selectedSubjectName}</p> */}
        <Select
          id="subject"
          mode="single"
          style={{ width: "100%" }}
          placeholder="Select Subject "
          defaultValue={[]}
          value={selectedSubject1}
          onChange={handleChangesubject1}
          optionLabelProp="label"
          options={options2}
          optionRender={(option) => <Space>{option.label}</Space>}
        />
        <Col md={24} sm={24} xs={24} className="mt-2">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          <AsyncPaginate
            isMulti
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          />
        </Col>
      </Modal>
    </div>
  );
}

export default BatchStudymaterial;
