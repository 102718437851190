import React, { useEffect, useState } from "react";
import { Col, Input, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";

import { Button, Modal, Space } from "antd";

function User() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");

  // const searchParams = new URLSearchParams(window.location.search);
  // const pageNumber = searchParams.get("page");

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  console.log(searchParams);
  console.log(page);
  console.log(searchData);

  const users_list = useSelector((state) =>
    state?.allapi?.users_list ? state?.allapi?.users_list : {}
  );

  const [pages, setPage] = useState(1);

  console.log(typeof page);
  console.log(typeof pages);

  const listdata = users_list?.data ? users_list?.data : [];

  useEffect(() => {
    if (search) {
      dispatch(allapiAction.getuserListSearch({ page: page, search: search }));
    } else {
      dispatch(allapiAction.getuserList(page));
    }

    return () => {};
  }, [location]);

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    dispatch(
      allapiAction.user_inactive({
        user_id: userId,
        active: 0,
        page: page,
      })
    );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Student Mobile",
      dataIndex: "student_mobile",
      key: "student_mobile",
    },
    {
      title: "Parent Name",
      dataIndex: "parent_name",
      key: "parent_name",
    },
    {
      title: "Parent Mobile",
      dataIndex: "parent_mobile",
      key: "parent_mobile",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title="Contact">
              <span
                className="cursor-pointer"
                onClick={() => navigate(`/users/contact/${item?.id}`)}
              >
                <SvgIcon.contacts className="scale-icon edit-icon" />
              </span>
            </Tooltip>
            <Tooltip title="delete">
              <button
                className="filter-button ml-3"
                onClick={() => showModal(item?.id)}
              >
                Delete
              </button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/users?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    navigate("/users?page=" + e + "&&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  return (
    <div>
      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Users"}
            subHeading={`Total ${users_list?.total || 0} Users`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          />
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        {/* <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => navigate("/user/add")}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div> */}
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: users_list?.total ? users_list?.total : 0,
          }}
        />
      </div>
    </div>
  );
}

export default User;
