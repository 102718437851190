// import React from "react";

// const SidebarFooter = () => {
//   return (
//     <div className="siderbar_footer">
//       © {new Date().getFullYear()}{" "}
//       <a href="https://devhubtech.com" target="_blank" rel="noreferrer">
//         devhubtech.com
//       </a>
//       <br />
//       Designed & Developed by
//       <br />
//       <a href="https://abym.in" target="_blank" rel="noreferrer">
//         Dev hob tech.
//       </a>
//     </div>
//   );
// };

// export default SidebarFooter;
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { LogoutOutlined } from '@ant-design/icons';
import { Button } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { DeleteToken } from '../../utils';

const SidebarFooter = ({ collapsed, setCollapsed }) => {
    const navigate = useNavigate();
    const handleLogout = () => {
        DeleteToken();
        navigate("/login", { replace: true });
    }
    return (
      <div>
        <div className='flex justify-content-between align-items-center'>
            {/* <div>{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: () => setCollapsed(!collapsed),
            })}</div> */}
            <div className='mr-20'>
                <span className='logout-btn' onClick={() => handleLogout()}>Logout<LogoutOutlined /></span>
            </div>
            </div>
            <div className="siderbar_footer">
     © {new Date().getFullYear()}{" "}
       <a href="https://devhubtech.com" target="_blank" rel="noreferrer">
        devhubtech.com
     </a>
       <br />
       Designed & Developed by
       <br />
      <a href="https://abym.in" target="_blank" rel="noreferrer">
        Dev hob tech.
       </a>
     </div>
        </div >
    )
}

export default SidebarFooter