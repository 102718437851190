export const URL = {
  // dev

  // API_BASE_URL: "http://192.168.0.65:8000",
  // API_BASE_URL: "https://appdevelopmentcompanyjaipur.com/aurra",
  // API_BASE_URL: "https://elite.bestsoftwareagency.com",

  // API_BASE_URL: "http://192.168.0.16:8000",
    // local  
  API_BASE_URL: "https://sea.computerclasses.in",   
  // live    

  // API_BASE_URL: "http://192.168.0.12:5500",
  // API_BASE_URL: "http://gamesdeveloper.in:3002",

  // apipublic

  login_admin: "/api/login_admin",
  users: "/api/users",
  user_contact: "/api/user_contact",
  all_category: "/api/all_category",
  all_category_main: "/api/all_category_main",
  delete_category: "/api/delete_category",
  update_category: "/api/update_category",
  create_quiz_category: "/api/create_quiz_category",
  quiz_list: "/api/quiz_list",
  create_quiz: "/api/create_quiz",
  delete_quiz: "/api/delete_quiz",
  update_quiz: "/api/update_quiz",
  // allPosts:"/api/getAllPosts",
  allPosts:"/api/all_posts",

  questions_list: "/api/questions_list",
  delete_question: "/api/delete_question",
  update_question: "/api/update_question",
  create_quiz_question: "/api/create_quiz_question",
  dashboard: "/api/dashboard",
  user_allcontact: "/api/user_allcontact",
  user_inactive: "/api/user_inactive",
  quiz_active: "/api/quiz_active",

  create_subject_category:"/api/subject_category",
  update_subject_category:"/api/update_subject_category",
  delete_subject_category:"/api/delete_subject_category",
  get_subject_category:"/api/get_subject_category",

  create_subject:"/api/create_subject",
  update_subject:"/api/update_subject",
  delete_subject:"/api/delete_subject",
  subjects_list:"/api/get_subjects",

  add_subjects:"/api/add_subjects",
  subjects_by_category:"/api/subjects_by_category", 
  deleteSubjectFromCategory:"/api/deleteSubjectFromCategory",

  create_batch:"/api/create_batch",
  update_batch:"/api/update_batch",
  delete_batch:"/api/delete_batch",
  batch_list:"/api/get_batch",
  get_batch_detail:"/api/get_batch_detail",

  // createPost:"/api/createPost",
  // delete_post:"/api/delete_post",
  // postLike:"/api/postLike",
  // getAllPosts:"/api/getAllPosts",
  // getMyPosts:"/api/getMyPosts",
  all_comments:"/api/all_comments",
  delete_comment:"/api/delete_comment_admin",
  admin_profile:"/api/admin_profile",
  admin_update:"/api/admin_update",   
  add_students:"/api/addStudentInBatch",
  students_list:"",
  add_study_material:"/api/add_study_material",
  getStudyMaterialBySubject:"/api/getStudyMaterialBySubject",
 
  add_study_material_to_batch:"/api/add_study_material_to_batch",

  getStudyMaterialSubjectPaginate: "/api/getStudyMaterialSubjectPaginate",
  getStudyMaterialAll:"/api/getStudyMaterialAll",
  delete_study_material:"/api/delete_study_material",
  // comment:"/api/comment",

  delete_post:"/api/delete_post_admin",


  createcategories: "/apiVendor/createcategories",
  getCategory: "/publicApi/getCategory",
  getsubsubcate: "/publicApi/getsubsubcate",
  getsubsubcateHome: "/publicApi/getsubsubcateHome",
  signupSeller: "/publicApi/signupSeller",
  otpVerify: "/publicApi/otpVerify",
  productDetails: "/publicApi/productDetails",
  getsellerProductListPublice: "/publicApi/getsellerProductListPublice",
  gettagsProductListPublice: "/publicApi/gettagsProductListPublice",
  getsellerProductListPublicefilCate:
    "/publicApi/getsellerProductListPublicefilCate",
  getsellerProductListPublicebycategory:
    "/publicApi/getsellerProductListPublicebycategory",
  getsellerprofile: "/publicApi/getsellerprofile",
  whatsappinquiresubmit: "/publicApi/whatsappinquiresubmit",
  getsubsubCategoryList: "/publicApi/getsubsubCategoryList",
  createSubscription: "/publicApi/createSubscription",
  getPages: "/publicApi/getPagesPu",
  emailenquirysubmit: "/publicApi/emailenquirysubmit",
  pageDetails: "/publicApi/pageDetails",
  searchProductpub: "/publicApi/searchProductpub",
  sendOtpSeller: "/publicApi/sendOtpSeller",
  otpVerifyseller: "/publicApi/otpVerifyseller",
  signinSellereSecurite: "/publicApi/signinSellereSecurite",
  footerPagepageListetPu: "/publicApi/footerPagepageListetPu",
  productclick: "/publicApi/productclick",
  getHomePro: "/publicApi/getHomePro",
  getSellerListPublic: "/publicApi/getSellerListPublic",
  getsellerProductListPublicebymaincategory2:
    "/publicApi/getsellerProductListPublicebymaincategory2",

  // apiAdmin
  signinSeller: "/publicApi/signinSeller",
  getsellerProductList: "/sellerApi/getsellerProductList",
  createSellerProduct: "/sellerApi/createSellerProduct",
  searchSellerProduct: "/sellerApi/searchSellerProduct",
  deleteSellerProduct: "/sellerApi/deleteSellerProduct",
  createBussnissPro: "/sellerApi/createBussnissPro",
  getBussProfile: "/sellerApi/getBussProfile",
  updateWhatsaapNumber: "/sellerApi/updateWhatsaapNumber",
  verifyWhatsapp: "/sellerApi/verifyWhatsapp",
  updateBussLogo: "/sellerApi/updateBussLogo",
  updateBussProfile: "/sellerApi/updateBussProfile",
  getPackageList: "/sellerApi/getPackageList",
  createPurchase: "/sellerApi/createPurchase",
  getsellerTransaction: "/sellerApi/getsellerTransaction",
  getprofile: "/sellerApi/getprofile",
  updatesellerProduct: "/sellerApi/updatesellerProduct",
  getSellerTicketList: "/sellerApi/getSellerTicketList",
  createSellerTickets: "/sellerApi/createSellerTickets",
  getmessageSupportList: "/sellerApi/getmessageSupportList",
  createMessage: "/sellerApi/createMessage",
  deleteacountByseller: "/sellerApi/deleteacountByseller",
  copyProduct: "/sellerApi/copyProduct",
  getInvoice: "/sellerApi/getInvoice",
  getEmailenquirySeller: "/sellerApi/getEmailenquirySeller",
  getsellerWhatsappinquire: "/sellerApi/getsellerWhatsappinquire",
  searchtags: "/sellerApi/searchtags",
  passwordChange: "/sellerApi/passwordChange",
  topViewnewSeller: "/sellerApi/topViewnewSeller",
  sellerDashboardState: "/sellerApi/sellerDashboardState",
  getExpireDate: "/sellerApi/getExpireDate",
  getsliderList: "/publicApi/getsliderList2",
  getBlogListPub: "/publicApi/getBlogListPub",
  getBlogDetails: "/publicApi/getBlogDetails",
  gethomePop: "/publicApi/gethomePop",
  getlistfaqs: "/publicApi/getlistfaqs",
  getInvoceDetails: "/publicApi/getInvoceDetails",
};
