import React, { useState, useEffect } from 'react';
import { Button, Card, Modal, Select, Space } from 'antd';
import Userlist from './Userlist';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, collection, getDocs, addDoc, serverTimestamp, deleteDoc } from 'firebase/firestore';
import { db } from '../Firebase';
import Chat from './Chat';
import Chatrealtime from './Test';
import Batchmembers from './Batchmembers';
import { useDispatch, useSelector } from 'react-redux';
import { allapiAction } from '../../Redux/common/action';
import { message } from 'antd';

const Chatbachinfo = ({batchid}) => {
  const { id } = useParams();

  console.log("batchid");
  

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [groupDetails, setGroupDetails] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [studentDetails, setStudentDetails] = useState([]);

  const showModal = () => {
    setIsModalOpen(true);
    getDataFire();

  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSelectedUsersChange = (users) => {
    setSelectedUsers(users);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const docRef = doc(db, 'Batch Chat', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setGroupDetails(docSnap.data());

        // Fetch student details from the 'Studentdetail' collection
        const studentDetailCollection = collection(db, `Batch Chat/${id}/Studentdetail`);
        const studentDetailSnapshot = await getDocs(studentDetailCollection);
        const students = [];

        studentDetailSnapshot.forEach((doc) => {
          students.push(doc.data());
        });

        setStudentDetails(students);
      } else {
        console.log('No such document!');
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };


  const [batchDetails, setbatchDetails] = useState({});
  useEffect(() => {
    fetchData1();
  }, []);
// console.log(batchid)
  const fetchData1 = async () => {
    try {
      const docRef = doc(db, 'Batch Chat', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setbatchDetails(docSnap.data());
       } else {
        console.log('No such document!');
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || 1;
  const search = searchParams.get('search') || '';

  // console.log(searchParams);
  // console.log(page);
  // console.log(search);

  const usersList = useSelector((state) =>
    state?.allapi?.users_list ? state?.allapi?.users_list : {}
  );

  const listData = usersList?.data ? usersList?.data : [];

 





  // const handleChange = (selectedValues) => {
  //   setSelectedUsers(selectedValues);
  //   // onSelectedUsersChange(selectedValues);
  // };
  const [toastMessage, setToastMessage] = useState('');
  const [batchData, setBatchdata] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  console.log(selectedStudents)
  const options = listData.map((user) => ({
    label: user.name,
    value: user.id,
    email: user.email,
    key: user.id, // Add a unique key for each option
    selected: selectedStudents.includes(user.id), // Check if the user is already selected
  }));
  
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const showModal1 = () => {
    setIsModalOpen1(true);
  };

  const handleCancel1 = () => {
    // setSelectedUsers
    setIsModalOpen1(false);
  };


// list data store in firebase



// const handleChange = ([selectedValues]) => {
//   // Update the state with unique selected user IDs
//   setSelectedStudents((prevSelectedStudents) => [
//     ...new Set([...prevSelectedStudents, ...selectedValues]),
//   ]);

//   // Note: The state update is asynchronous, so the console.log here may not reflect the updated state immediately
//   console.log('Updated selectedStudents:', selectedStudents);
// };

const handleChange = (selectedValues) => {
  setSelectedStudents(selectedValues);
  console.log('Updated selectedStudents:', selectedValues);
};



// console.log(selectedStudents)
const handleOksubmit = async (e) => {
  e?.preventDefault();
  console.log("submitted");

  try {
    const batchRef = collection(db, `Batch Chat/${batchid}/Studentdetail`);

    // Store each selected student separately
    for (const studentId of selectedStudents) {
      const selectedStudent = listData.find((user) => user.id === studentId);

      if (selectedStudent) {
        await addDoc(batchRef, {
          studentId,
          email: selectedStudent.email,
          name: selectedStudent.name,
          timestamp: serverTimestamp(),
        });
      }
    }
    getDataFire();
    message.success('Members added successfully');
    console.log("Documents written successfully");
  } catch (error) {
    message.error('Failed to add members');
    console.error("Error writing documents: ", error);
  }
setIsModalOpen(true);
  setIsModalOpen1(false);
  setSelectedStudents([]);
};

// const [batchData, setBatchdata] = useState([]);
// useEffect(() => {
//   getDataFire();
//   // handleToGetProfile();
// }, []);
const getDataFire = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, `Batch Chat/${batchid}/Studentdetail`));
    const batchDataArray = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setBatchdata(batchDataArray);
    console.log(batchDataArray)
const olduser = batchDataArray  && batchDataArray?.map((data)=>{
  return data?.studentId
})
console.log(olduser);

    // studentId
    setSelectedStudents(olduser)
    console.log(batchDataArray)
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
};

const handleDeleteUser = async (userId) => {
  try {
    const batchRef = collection(db, `Batch Chat/${batchid}/Studentdetail`);
    const userDoc = await doc(batchRef, userId);
    await deleteDoc(userDoc);
    message.success('User deleted successfully');
    getDataFire(); // Refresh the data after deletion
  } catch (error) {
    message.error('Failed to delete user');
    console.error('Error deleting user:', error);
  }
};
  return (
    <div>
      <div className='chatbatchpositionset'>
        <Button type="primary" onClick={showModal}  className='userlisticon'>
          <p><img src="images/users5.png" alt="users icon" /></p>
        </Button>
      </div>
      <Modal title="Basic Modal" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        {/* ... (existing code) */}
       
          {/* <Userlist batchid={id} onSelectedUsersChange={handleSelectedUsersChange} /> */}
          <>
      <Button type="primary" onClick={showModal1} style={{border:"none",}}>
        Add member
      </Button>
      {/* <Modal title="Basic Modal" open={isModalOpen1} onOk={handleOksubmit} onCancel={handleCancel1}> */}
      <Modal
  title="Basic Modal"
  visible={isModalOpen1}
  onOk={handleOksubmit}
  className='modelviewset'
  onCancel={handleCancel1}
>
  <Select
  id="studentname"
  mode="multiple"
  style={{ width: '100%' }}
  placeholder="Select users"
  defaultValue={[]} // This prop is usually used for initial values, consider removing it
  value={selectedStudents}
  onChange={handleChange}
  optionLabelProp="label"
  options={options}
  optionRender={(option) => (
    <Space>
      {option.label}
    </Space>
  )}
/>

{/* <p>Selected Users:</p>
<ul>
  {selectedStudents.map((user) => (
    <li key={user}>
      {user?.name}
      {/* Add some indicator, e.g., a checkmark or styling to indicate selection */}
      {/* <span className="selected-indicator"> ✓ </span>
    </li>
  ))} </ul>  */}

</Modal>
    </>
    
        <div className='uldesign'>
        <p style={{ fontWeight: '500' }}>Members:</p>
        <ul>
      {batchData.map((data, index) => (
        
        <li key={index} className="setinfoposition1">
          <img src={data.icon} style={{ width: '46px', borderRadius: '10px' }} />
          <p>{data.name}
          <span className='btnpositionset'><Button type="danger" onClick={() => handleDeleteUser(data.id)}>
            <img src="images/remove.png" 
className='remove-icon'/>
          </Button></span></p>
        </li>
         
      ))}
      </ul>
      </div>
    </Modal>
    </div>
  );
};

export default Chatbachinfo;
