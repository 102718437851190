import React, { useEffect, useRef, useState } from "react";
import { Table, Tooltip, Col, Form, Input, Select } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";

import {
  ExclamationCircleFilled,
  FileOutlined,
  FilePdfOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import { toast } from "react-toastify";
import { URL } from "../../Redux/common/url";
import { FileUploader } from "react-drag-drop-files";

function Studymaterial() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const [selectedSubject, setSelectedSubject] = useState();
  const [selectedSubjects, setSelectedSubjects] = useState();
  console.log(selectedSubject);

  const handleChangesubject = (selectedValues) => {
    setSelectedSubject(selectedValues); // Set selectedValues directly
  };

  const allmaterialList = useSelector((state) =>
    state?.allapi?.allmaterialList ? state?.allapi?.allmaterialList : {}
  );

  console.log(allmaterialList);

  const listdata = allmaterialList?.data ? allmaterialList?.data : [];

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [showDelelt, setShowDelete] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);

  const hendleDelete = (e) => {
    // console.log(e);
    // deleteProduct
    setShowDelete(false);
    dispatch(allapiAction.delete_batch({ id: e, page: page }));
    dispatch(allapiAction.study_materiallist({ page: page }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [cateID, setcateID] = useState("");

  const hendleModalShow = (e) => {
    // setShowDelete(true)
    setcateID(e);
    setIsModalOpen(true);
  };

  const hendleToSubmitDelete = () => {
    dispatch(allapiAction.delete_study_material({ id: cateID, page: page }));

    setIsModalOpen(false);
  };

  const [cateDetail, setcateDetail] = useState({});
  const [selectedFile, setSelectedFile] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);

  const fileInputRef = useRef(null);
  const [filePreviews, setFilePreviews] = useState([]);

  console.log(filePreviews);
  console.log(selectedFile);

  // const removeCourse = (event) => {
  //   console.log(event);
  //   let array = [...selectedFile];
  //   let array2 = [...filePreviews];
  //   let coun = array.indexOf(event);
  //   let coun2 = array2.indexOf(event);
  //   if (coun > -1) {
  //     array.splice(coun, 1);
  //     array2.splice(coun2, 1);
  //     setSelectedFile(array);
  //     setFilePreviews(array2);
  //   }
  // };
  // for single file
  // const handleFileSelect = (event) => {
  //   setSelectedFile(event.target.files[0]);
  //   const previewUrl = window.URL.createObjectURL(event.target.files[0]);

  // setImagePreview(previewUrl);
  // };
  // for single file
  const handleFileSelect = (event) => {
    const files = event.target.files;

    console.log(files);
    const selectedFilesArray = Array.from(files);
    // setSelectedFile(selectedFilesArray);
    // const previews = selectedFilesArray.map((file) => ({
    //   name: file.name,
    //   preview: window.URL.createObjectURL(file),
    // }));

    // setFilePreviews(previews);

    setSelectedFile([...selectedFile, ...selectedFilesArray]);
    const previews = selectedFilesArray.map((file) => ({
      name: file.name,
      preview: window.URL.createObjectURL(file),
    }));

    setFilePreviews([...filePreviews, ...previews]);
  };

  // const showModal = (e) => {
  //   // console.log(e);
  //   setcateDetail(e);
  //   setsubDetail(e);
  //   setIsModalOpen(true);
  // };
  const showModal = (item) => {
    setcateDetail(item);
    setSelectedSubject(item.subject_id);
    setSelectedSubjects(item.course_id);
    setIsModalOpen(true);
  };

  // const [selectedFile1, setSelectedFile1] = useState(null);

  // const handleFileSelect1 = (event) => {
  //   setSelectedFile1(event.target.files[0]);
  // };
  const hendleTochangeCategory = (e) => {
    const { name, value } = e.target;
    setcateDetail((prevData) => ({ ...prevData, [name]: value }));
    console.log("Updated cateDetail:", cateDetail);
  };

  const handleOk = () => {
    const data = new FormData();

    if (!cateDetail?.id) {
      toast.error("Invalid category ID.");
    } else {
      data.append("id", cateDetail?.id);

      if (cateDetail?.name) {
        data.append("name", cateDetail?.name);
      }
      if (selectedFile) {
        data.append("image", selectedFile);
      }

      dispatch(allapiAction.update_batch(data));

      setIsModalOpen(false);

      dispatch(allapiAction.allmaterialList(page));
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [batchdata, setbatchdata] = useState();
  const [cateDetailAdd, setcateDetailAdd] = useState({});
  // console.log(cateDetailAdd)
  const hendleTochangeCategoryAdd = (e) => {
    const { name, value } = e.target;
    setcateDetailAdd({ ...cateDetailAdd, [name]: value });
    setbatchdata({ ...cateDetailAdd, [name]: value });
  };

  const showModalAdd = (e) => {
    // console.log(e);
    setcateDetail(e);
    setsubDetail(e);
    setAddModalOpen(true);
  };
  //   const handleOkAdd = async() => {
  //     if (!cateDetailAdd?.name) {
  //       toast.error("please enter name");
  //     } else {
  //       console.log("subDetail")
  //       const data = new FormData();
  //       data.append("page", 1);
  //       data.append("course_id", selectedSubjects);
  //       data.append("subject_id", selectedSubject);
  //       data.append("name", cateDetailAdd?.name);
  //       data.append("documents", selectedFile);

  //     //  const response =
  //       await dispatch(allapiAction.add_studymaterial(data))

  //     //  console.log(response);
  //       // const batchChatCollection = collection(db, `BatchChat/${id}/messages`)
  //       // const firestoreDocumentId = response?.data?.id;

  //     // Now, store the data in Firestore with the generated document ID

  //       setcateDetailAdd({  });
  //       setSelectedFile(null);
  //       // dispatch(adminAction.getRoleList('dhsg'))
  //       setAddModalOpen(false);
  //       // dispatch(allapiAction.study_materiallist({page:page}));
  //       return () => {};
  //     }

  // };

  const handleOkAdd = async () => {
    const data = new FormData();
    data.append("page", 1);
    data.append("course_id", selectedSubjects);
    data.append("subject_id", selectedSubject);
    data.append("name", cateDetailAdd?.name);

    if (Array.isArray(selectedFile)) {
      selectedFile.forEach((file, index) => {
        data.append(`documents[${index}]`, file);
      });
    } else {
      // If it's not an array, you can handle it accordingly.
      // For example, if it's a single file, you can append it directly.
      // data.append("documents", selectedFile);
    }
    // If it's not an array, you can handle it accordingly.
    // For example, if it's a single file, you can append it directly.

    data.append("description", cateDetailAdd?.description);

    const response = await dispatch(allapiAction.add_studymaterial(data, page));
    console.log(data);
    console.log(response);
    dispatch(allapiAction.allmaterialList({ page }));
    setcateDetailAdd({ name: "", description: "" });
    setSelectedFile([]);
    setFilePreviews([]);
    setAddModalOpen(false);
    console.log("Selected File:", selectedFile);
    console.log("FormData:", data);

    console.log("API Response:", response);
  };

  useEffect(() => {
    var formdata = new FormData();
    formdata.append("page", "1");
    dispatch(allapiAction.allmaterialList({ page: 1 }));
  }, []);

  const handleCancelAdd = () => {
    setAddModalOpen(false);
    setSelectedSubjects([]);
    setSelectedFile([]);
    setFilePreviews([]);
    setcateDetailAdd({ name: "", description: "" });
  };

  const { confirm } = Modal;

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const getImageUrl = (path) => {
    return `${URL.API_BASE_URL}${path}`;
  };
  const getFileExtension = (url) => {
    const parts = url.split(".");
    return parts[parts.length - 1];
  };
  const getFileExtension2 = (url) => {
    console.log(url);
    const parts = url.split("/");

    console.log(parts);
    const filenamesget = parts[parts.length - 1];

    return parts[parts.length - 1];
  };

  // Utility function to get icon based on file extension
  // Utility function to get icon based on file extension
  // Utility function to get icon based on file extension
  const getIconForExtension = (extension, imageUrl) => {
    switch (extension.toLowerCase()) {
      case "pdf":
        return (
          <FilePdfOutlined
            style={{
              color: "red",
              marginRight: "5px",
              marginLeft: "3px",
              marginTop: "-4px",
            }}
          />
        );
      case "doc":
      case "docx":
        return (
          <FileOutlined
            style={{
              color: "blue",
              marginRight: "5px",
              marginLeft: "3px",
              marginTop: "-4px",
            }}
          />
        );
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return (
          <img
            src={imageUrl}
            alt="Image File"
            style={{ width: "20px", marginRight: "5px", marginLeft: "3px" }}
          />
        );
      // Add more cases for other file types as needed
      default:
        return <FileOutlined />;
    }
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Material Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Documents",
      dataIndex: "documents",
      key: "documents",
      render: (documents, record) => (
        <div>
          {documents && documents.length > 0 ? (
            documents.map((document, index) => (
              <a
                key={index}
                href={getImageUrl(document.document)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* {index + 1} */}
                {getIconForExtension(
                  getFileExtension(document.document),
                  getImageUrl(document.document)
                )}
                {/* <span>{getFileExtension(document.document)}</span> , */}
                <span>{getFileExtension2(document.document)}</span> ,
              </a>
            ))
          ) : (
            <span>No documents</span>
          )}
        </div>
      ),
    },

    // {
    //   title: "Attachment Type",
    //   dataIndex:  ["type", "name"],
    //   key: "name",
    // },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Stu">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showModalstudent(item?.id);
                }}
              >
                <UsergroupAddOutlined className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
            {/* <Tooltip title="Edit">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showModal(item);
                }}
              >
                <SvgIcon.Edit className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
            <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                  hendleModalShow(item?.id);
                }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip>
            {/* <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showDeleteConfirm(item?.id);
                }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
          </>
        );
      },
    },
  ];

  //   useEffect(() => {
  //     var formdata = new FormData();
  // formdata.append("subject_id",selectedSubjects );
  // formdata.append("course_id",selectedSubject );
  // formdata.append("page", );
  //     dispatch(allapiAction.study_materiallist({
  //       formdata,
  //     }));

  //   return () => {};
  // }, [selectedSubject, selectedSubjects, page]);

  // const [page, setPage] = useState(1);
  const [search, setsearch] = useState("");
  const handleChange = (e) => {
    // console.log(e);
    setsearch(e);
    setPage(1);
    if (e) {
      dispatch(allapiAction.batch_listSearch({ page: 1, search: e }));
      return () => {};
    } else {
      dispatch(allapiAction.study_materiallist(1));

      return () => {};
    }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);
    if (search) {
      dispatch(allapiAction.batch_listSearch({ page: e, search: search }));
      return () => {};
    } else {
      dispatch(allapiAction.allmaterialList({ page: e }));

      return () => {};
    }
  };
  // updated
  const [subDetail, setsubDetail] = useState({});
  // console.log(subDetail);
  const subject_category_list = useSelector((state) =>
    state?.allapi?.subject_category_list
      ? state?.allapi?.subject_category_list
      : {}
  );

  // console.log(subject_category_list);

  const subcategorylistdata = subject_category_list?.data
    ? subject_category_list?.data
    : [];
  useEffect(() => {
    dispatch(allapiAction.subject_category_list(1));

    return () => {};
  }, []);

  const options = subcategorylistdata.map((user) => ({
    label: user.name,
    value: user.id,
    // email: user.email, // Add the email property if it exists in your user object
    // Add other properties as needed
  }));

  // create batch ka use krna h

  const handleChangesub = (selectedValue) => {
    setSelectedSubjects(selectedValue);
    if (selectedValue) {
      dispatch(allapiAction.subjects_by_category(selectedValue));
    } else {
      // Handle the case when no subject category is selected
      // You may want to clear the options or do something else
    }
  };

  // for subjectlist

  // const subjects_by_category = useSelector((state) =>
  //   state?.allapi?.subjects_by_category ? state?.allapi?.subjects_by_category : {}
  // );

  const subjectsByCategory = useSelector(
    (state) => state?.allapi?.subjects_by_category
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formdata = new FormData();
        formdata.append("category_id", selectedSubjects);
        await dispatch(allapiAction.subjects_by_category(formdata));
      } catch (error) {
        console.error("Error fetching subjects by category:", error);
      }
    };
    setSelectedSubject(null);
    if (selectedSubjects) {
      fetchData();
    }
  }, [selectedSubjects]);

  // const subjectlistdata = subjectsByCategory?.data || [];

  console.log(subjectsByCategory?.subjects);
  const subjectlist = subjectsByCategory?.subjects;

  const options2 = (subjectlist || []).map((subjectlist) => ({
    label: subjectlist?.subject?.name,
    value: subjectlist?.subject?.id,
  }));

  // console.log(subjectsByCategory);
  // console.log(subjectlistdata);

  // create batch ka use krna h

  // close subjectlist

  // console.log(subDetail);

  // for student add
  //   const users_list = useSelector((state) =>
  //   state?.allapi?.users_list ? state?.allapi?.users_list : {}
  //   );

  //   const listData = users_list?.data ? users_list?.data : [];
  //   useEffect(() => {
  //     dispatch(allapiAction.users_list(1));

  //     return () => {};
  //   }, []);

  //   const [selectedStudents, setSelectedStudents] = useState([]);
  //     console.log(selectedStudents)
  //     const options1 = listData.map((user) => ({
  //       label: user.name,
  //       value: user.id,
  //       email: user.email,
  //       key: user.id, // Add a unique key for each option
  //       selected: selectedStudents.includes(user.id), // Check if the user is already selected
  //     }));
  //     const [isModalOpen1, setIsModalOpen1] = useState(false);
  //     const showModal1 = () => {
  //       setIsModalOpen1(true);
  //     };

  //     const handleCancel1 = () => {
  //       setIsModalOpens(false);
  //       setIsModalOpenstudent(false);
  //     };

  //   const [isModalOpenstudent, setIsModalOpenstudent] = useState(false);

  //  const add_students = useSelector((state) =>
  //  state?.allapi?.add_students ?  state?.allapi?.add_students : {}
  //  );

  //  console.log(add_students)

  // console.log(selectedStudents);

  // // const handleChangestudent = (selectedValues) => {
  // //   setSelectedStudents([...new Set([...selectedStudents, ...selectedValues])]);
  // // };

  // const [isModalOpens, setIsModalOpens] = useState(false);

  // const handleChangestudent = (selectedValues) => {
  //   setSelectedStudents(selectedValues);
  //   console.log('Updated selectedStudents:', selectedValues);
  // };

  //   const handleOkAddStudents = async (e) => {
  //     e?.preventDefault();
  //     console.log('submitted');

  //     let data = new FormData();
  //     data.append("category_id", subDetail);

  //     // Iterate over selectedSubjects array and append each subject to FormData
  //     selectedStudents.forEach((students, index) => {
  //       data.append(`students[${index}]`, students);
  //     });
  //     setIsModalOpens(false);
  //     // redux
  //     dispatch(allapiAction.add_subjects(data));

  //     console.log(data);
  //   }

  const handleDropZoneClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  // Function to handle drop zone drag over
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // Function to handle drop zone drop
  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFilePreviews(files);
    const selectedFilesArray = Array.from(files);

    setSelectedFile([...selectedFile, ...selectedFilesArray]);
  };

  // const files = event.target.files;

  // console.log(files);
  // const selectedFilesArray = Array.from(files);

  // setSelectedFile([...selectedFile, ...selectedFilesArray]);

  // Function to handle file previews
  const handleFilePreviews = (files) => {
    const previews = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();

      reader.onload = (e) => {
        previews.push({
          name: file.name,
          preview: e.target.result,
        });

        if (previews.length === files.length) {
          setFilePreviews(previews);
        }
      };

      reader.readAsDataURL(file);
    }
  };
  const isDocument = (fileName) => {
    const documentExtensions = [".pdf", ".doc", ".docx", ".ppt", ".pptx"]; // Add more extensions as needed
    const lowerCaseFileName = fileName.toLowerCase();
    return documentExtensions.some((ext) => lowerCaseFileName.endsWith(ext));
  };

  const fileTypes = ["JPG", "PNG", "GIF"];
  const [file, setFile] = useState(null);
  const handleChange5 = (file) => {
    setFile(file);
  };

  console.log(file);
  return (
    <div>
      <div className="batchfield">
        {/* student */}
        {/* <div className="modalselectbtn">
      <Modal
        title="Add sub Category"
        open={isModalOpenstudent}
        onOk={handleOkAddStudents}
        onCancel={handleCancel1}
      >
  <Col lg={24} md={24} sm={24} xs={24}>
  <Select
          id="students"
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Select Subject"
          defaultValue={[]}
          value={selectedStudents}
          onChange={handleChangestudent}
          optionLabelProp="label"
          options={options1}
          optionRender={(option) => (
            <Space>
              {option.label}
            </Space>
          )}
        />
        </Col> */}
        {/* <div className='uldesign'>
        <p style={{ fontWeight: '500' }}>Members:</p>
        <ul>
      {batchData.map((data, index) => (
        
        <li key={index} className="setinfoposition1">
          <img src={data.icon} style={{ width: '46px', borderRadius: '10px' }} />
          <p>{data.name}
          <span className='btnpositionset'><Button type="danger" onClick={() => handleDeleteUser(data.id)}>
            <img src="images/remove.png" 
className='remove-icon'/>
          </Button></span></p>
        </li>
         
      ))}
      </ul>
      </div> */}
        {/* </Modal>
      </div> */}
        {/* student close */}
        {/* update */}
        {/* <Modal
        title="Update Study Material"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Col lg={24} md={24} sm={24} xs={24}>
        <Select
          id="subjects"
          mode="single"
          style={{ width: '100%' }}
          placeholder="Select users"
          defaultValue={[]}
          value={selectedSubjects}
        //   onChange={handleChangecateDetailsub}
          optionLabelProp="label"
          options={options}
          optionRender={(option) => (
            <Space>
              {option.label}
            </Space>
          )}
        />
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="Name"
            name="name"
            value={cateDetail?.name}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
         <input
  type="file"
  className="form-control"
  name="documents"
  onChange={handleFileSelect}
/>

{selectedFile ? (
  <img
    src={window.URL.createObjectURL(selectedFile)}
    alt="Selected Image"
    style={{ width: '70px', height: '70px', borderRadius: '50%', marginTop: '10px' }}
  />
) : (
  !selectedFile && cateDetail?.image && (
    <img
      src={getImageUrl(cateDetail?.image)}
      alt={cateDetail?.image}
      style={{ width: '70px', height: '70px', borderRadius: '50%', marginTop: '10px' }}
    />
  )
)}


        </Col>
      </Modal> */}

        {/* create */}

        <Modal
          title="Delete"
          open={isModalOpen}
          onOk={hendleToSubmitDelete}
          onCancel={() => {
            setIsModalOpen(false);
          }}
        >
          <p> Are you sure</p>
        </Modal>

        <Modal
          title="Study Material"
          open={addModalOpen}
          onOk={handleOkAdd}
          onCancel={handleCancelAdd}
        >
          <Col lg={24} md={24} sm={24} xs={24}>
            <Select
              id="subjects"
              mode="single"
              style={{ width: "100%" }}
              placeholder="Select Course"
              defaultValue={[]}
              value={selectedSubjects}
              onChange={handleChangesub}
              optionLabelProp="label"
              options={options}
              optionRender={(option) => <Space>{option.label}</Space>}
            />

            <Select
              id="subject"
              mode="single"
              style={{ width: "100%", marginTop: "25px" }}
              placeholder="Select Subject "
              defaultValue={[]}
              value={selectedSubject}
              // onChange={(value) => setSelectedSubjects(value)}
              onChange={handleChangesubject}
              optionLabelProp="label"
              options={options2}
              optionRender={(option) => <Space>{option.label}</Space>}
            />
            <input
              autoComplete="new-off"
              className="form-control"
              placeholder="Title"
              name="name"
              value={cateDetailAdd?.name}
              onChange={(e) => {
                hendleTochangeCategoryAdd(e);
              }}
            />
            <textarea
              autoComplete="new-off"
              className="form-control"
              placeholder="Description"
              name="description"
              value={cateDetailAdd?.description}
              onChange={(e) => {
                hendleTochangeCategoryAdd(e);
              }}
            />
            <div
              onClick={handleDropZoneClick}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              style={{
                border: "2px dashed #ccc",
                borderRadius: "4px",
                padding: "20px",
                textAlign: "center",
                cursor: "pointer",
                marginTop: "20px",
              }}
            >
              <p>Click or drag and drop files here</p>
            </div>
            <input
              ref={fileInputRef}
              type="file"
              multiple
              className="form-control"
              name="documents"
              onChange={handleFileSelect}
              style={{ display: "none" }}
            />
            {/* <div>
  {filePreviews.map((file, index) => (
    <div key={index}>
      <p>{file.name}</p>
      {isDocument(file.name) ? (
        // If it's a document, use Google Docs Viewer with an iframe
        <iframe
          title={`Document Preview - ${file.name}`}
          src={`https://docs.google.com/gview?url=${encodeURIComponent(file.preview)}&embedded=true`}
          frameBorder="0"
          width="100%"
          height="400"
        >
        </iframe>
      ) : (
        // If it's an image, display the image
        <img src={file.preview} alt={file.name} style={{ maxWidth: '100px', maxHeight: '100px' }} />
      )}
    </div>
  ))}
</div> */}
            <div>
              {filePreviews.map((file, index) => (
                <div key={index}>
                  <p>{file.name}</p>
                  {file.name.toLowerCase().endsWith(".pdf") ? (
                    <a
                      href={file.preview}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FilePdfOutlined
                        style={{ fontSize: "24px", color: "red" }}
                      />
                      Open PDF
                    </a>
                  ) : (
                    <div>
                      <img
                        src={file.preview}
                        alt={file.name}
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                        // onClick={(e) => {
                        //   removeCourse(file);
                        // }}
                      />
                      {file.name.toLowerCase().endsWith(".jpg") && (
                        <span>JPG File</span>
                      )}

                      {/* Add more conditions for other file types */}
                    </div>
                  )}
                </div>
              ))}

              {/* <FileUploader
                handleChange={handleChange5}
                name="file"
                multiple 
                types={fileTypes}
              /> */}
            </div>
          </Col>
        </Modal>

        <div className="flex">
          <div>
            <HeadingBack
              title={"Study Material"}
              // subHeading={`Total ${study_materiallist?.total || 0} Batch`}
            />
          </div>
          <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
            {/* <Input
              type="text"
              autoComplete="new-off"
              className="form-control"
              onChange={(e) => handleChange(e.target.value)}
              placeholder="Search..."
              // value={values?.TestCode}
            /> */}
            {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
          </Col>

          <div className="flex1 flex justify-content-end align-items-center">
            <Tooltip title="Add">
              <span className="jop_btn" onClick={() => showModalAdd()}>
                Add
                <SvgIcon.Add
                  width={40}
                  height={40}
                  className="cursor-pointer"
                />
              </span>
            </Tooltip>
          </div>
        </div>

        <div className="tableResponsive mt-10">
          <Table
            dataSource={listdata}
            columns={columns}
            className={"table antd_table"}
            pagination={{
              current: page,
              pageSize: 10,
              onChange(current, pageSize) {
                hendlePahegi(current);
              },
              hideOnSinglePage: true,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Studymaterial;
